// Modules
import styled from "styled-components";

// Constants
import colors from "../utils/colors";

const QuickFormBaseInput = styled.input`
  appearance: none;
  border: none;
  font-family: Avenir, helvetica, sans-serif;
  font-size: 16px;
  padding: 7px 13px;

  &:focus {
    outline: 0;
  }
`;

export const QuickFormContainer = styled.form`
  border-radius: 5px;
  display: inline-block;
`;

export const QuickFormInput = styled(QuickFormBaseInput)`
  width: 190px;
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  border-radius: 5px 0 0 5px;
  margin-right: 10px;
`;

export const QuickFormSubmitButton = styled(QuickFormBaseInput)`
  background: ${colors.cobalt};
  border: 1px solid ${colors.cobalt};
  border-radius: 5px;
  box-shadow: 0 0 0 0 ${colors.cobalt};
  color: white;
  cursor: pointer;
  transition: box-shadow 100ms;
  &:active {
    box-shadow: 0 0 0 3px ${colors.caribbean};
  }
`;

export const QuickFormMessage = styled.div`
  color: ${colors.caribbean};
  font-size: 0.75rem;
  line-height: 100%;
  margin-top: 5px;
  text-align: left;

  &.error {
    color: ${colors.red};
  }
`;
