import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../utils/colors";
import { windowSize } from "../utils/responsive";
import Img from "gatsby-image";

const Feature = ({ children, image=null, imageAlt="", isImageRight= false, stripe= false, style }) => (
  <FeatureWrapper stripe={stripe} style={style}>
    <FeatureContainer isImageRight={isImageRight}>
      <FeatureImage fluid={image} alt={imageAlt} isImageRight={isImageRight} />
      <FeatureText isImageRight={isImageRight}>{children}</FeatureText>
    </FeatureContainer>
  </FeatureWrapper>
);

Feature.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  isImageRight: PropTypes.bool
};

Feature.defaultProps = {
  isImageRight: false
};

export default Feature;

const FeatureWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 600px;
  background-color: ${props => (props.stripe ? colors.lightBlue : colors.white)};
  justify-content: center;
  padding-top:90px;
  padding-bottom: 60px;
`

const FeatureContainer = styled.div`
  max-width: 800px;
  display: flex;
  padding: 60px;
  flex-direction: ${props => (props.isImageRight ? "row-reverse" : "row")};
  justify-content: space-between;
  @media (max-width: ${windowSize.medium}px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const FeatureImage = styled(Img)`
  width: 245px;
  margin: ${props => (props.isImageRight ? "0 0 0 30px" : "0 30px 0 0")};
  @media (max-width: ${windowSize.medium}px) {
    margin: 0;
    width: 50%;
    align-self: center;
  }
`;

const FeatureText = styled.div`
  display: flex;
  flex: 1.25;
  flex-direction: column;
  justify-content: center;
  margin: ${props => (props.isImageRight ? "0 30px 0 0" : "0 0 0 30px")};

  @media (max-width: ${windowSize.medium}px) {
    margin: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
