import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import {
  QuickFormInput,
  QuickFormContainer,
  QuickFormSubmitButton
} from "../components/QuickForm";
import Analytics from "../utils/tracking";

const SignUpForm = ({ handleSubmit, placeholder, submitText }) => {
  const [text, setText] = useState("");

  const clearText = () => {
    setText("");
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  const onSubmit = async (event) => {
    await Analytics.track("New Lead", {email: text})
    event.preventDefault();
    handleSubmit(text, clearText);
  };

  return (
    <QuickFormContainer onSubmit={onSubmit}>
      <QuickFormInput
        type="email"
        onChange={handleChange}
        placeholder={placeholder}
        value={text}
        required
      />
      <QuickFormSubmitButton type="submit" value={submitText} />
    </QuickFormContainer>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  submitText: PropTypes.string
};

SignUpForm.defaultProps = {
  placeholder: "",
  submitText: "Submit"
};

export default SignUpForm;
