import React, { useState } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroSection, { HeroImage } from "../components/HeroSection";
import Section from "../components/Section";
import { columnSize } from "../utils/responsive";
import Feature from "../components/Feature";
import { graphql, useStaticQuery } from "gatsby";
import SignUpForm from "../components/SignUpForm";
import notificationService from "../utils/notificationService";
import Notification from "../components/Notification";

const CorporatePage = () => {
  const [message, setMessage] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      corpHero: file(relativePath: { eq: "corp-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      loyalty: file(relativePath: { eq: "loyalty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      engage: file(relativePath: { eq: "engage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      insight: file(relativePath: { eq: "insight.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleSubmit = (email, clearText) => {
    const payload = { email };

    notificationService
      .notify(payload)
      .then(resp => {
        setMessage({
          text: "Thanks! We will get back to you soon.",
          type: "success"
        });
        clearText();
      })
      .catch(error => {
        setMessage({
          text: "Oops! Something went wrong.",
          type: "error"
        });
        clearText();
      });
  };

  return (
    <Layout>
      <SEO
        title="Engage and reward your retail shareholders, year-round."
        url={"/corporate/"}
        description={
          "Stockperks is an innovative platform that attracts and engages retail\n" +
          "          shareholders by offering perks, free products, discounts and exclusive\n" +
          "          experiences."
        }
        image={data.corpHero.childImageSharp.fluid.src}
      />
      <HeroSection isDarkBackground={true}>
        <h1>Engage and reward your retail shareholders, year-round.</h1>
        <HeroImage
          fluid={data.corpHero.childImageSharp.fluid}
          alt="Perk Central app screenshot"
        />
      </HeroSection>
      <Section isTextCentered={true} maxWidth={columnSize.medium}>
        <h2>
          Introducing Stockperks, an exciting innovation in Investor Relations
          not to be missed.
        </h2>
        <p>
          Stockperks is an innovative platform that attracts and engages retail
          shareholders by offering perks, free products, discounts and exclusive
          experiences.
        </p>
        <p>
          Promoting perks on the Stockperks app allows you to reward investor
          loyalty and demonstrate your company’s commitment to increased
          shareholder value. Through the platform, you will gain insights into
          your retail investor demographics and sentiment, and benefit from a
          modern and direct channel for communicating with this valued segment
          of your shareholder base.
        </p>
        <p>Welcome to the new golden age of investing.</p>
        <br />
        <h3>Become a corporate member today</h3>
        <SignUpForm
          handleSubmit={handleSubmit}
          placeholder="Email"
          submitText="Connect"
        />
        <br />
        {message && message.text && (
          <Notification text={message.text} type={message.type || null} />
        )}
      </Section>
      <Section>
        <Feature
          image={data.loyalty.childImageSharp.fluid}
          imageAlt="Reward Loyalty screenshot"
        >
          <h2>Reward Loyalty</h2>
          <p>
            Reward your retail shareholders for their loyalty with exciting
            perks, including exclusive product offerings, discounts, free items,
            special experiences and more.
          </p>
        </Feature>
        <Feature
          image={data.insight.childImageSharp.fluid}
          imageAlt="Gain Insight screenshot"
          isImageRight={true}
        >
          <h2>Gain Insight</h2>
          <p>
            Gain aggregated insight into the demographics, sentiment and
            investment characteristics of your retail shareholder base. Use
            these insights to craft more effective retail investor programs.
          </p>
        </Feature>
        <Feature
          image={data.engage.childImageSharp.fluid}
          imageAlt="Engage screenshot"
        >
          <h2>Engage</h2>
          <p>
            Engage with retail investors and forge a deeper relationship by
            communicating directly through sentiment surveys, powerful messaging
            features, and marketing campaigns.
          </p>
        </Feature>
      </Section>
    </Layout>
  );
};

export default CorporatePage;
