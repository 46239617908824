const HOST_URL =
  "https://jki6ajinb9.execute-api.us-east-1.amazonaws.com/production";

export default {
  notify: payload => {
    return fetch(`${HOST_URL}/deal`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
  }
};
