import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import colors from "../utils/colors";

const Notification = ({ type, text }) => (
  <NotificationContainer type={type}>{text}</NotificationContainer>
);

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["error", "success", "warning"])
};

export default Notification;

const NotificationContainerDefault = css`
  border: 1px solid ${colors.darkGray};
  color: ${colors.darkGray};
`;

const NotificationContainerError = css`
  border: 1px solid ${colors.red};
  color: ${colors.red};
`;

const NotificationContainerSuccess = css`
  border: 1px solid ${colors.caribbean};
  color: ${colors.caribbean};
`;

const NotificationContainerWarning = css`
  border: 1px solid ${colors.darkCanary};
  color: ${colors.darkCanary};
`;

const handleColorType = type => {
  switch (type) {
    case "error":
      return NotificationContainerError;
    case "success":
      return NotificationContainerSuccess;
    case "warning":
      return NotificationContainerWarning;
    default:
      return NotificationContainerDefault;
  }
};

const NotificationContainer = styled.div`
  ${props => handleColorType(props.type)}
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  padding: 7px 10px;
  margin: 10px auto;
`;
